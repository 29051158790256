import * as Amplitude from "@amplitude/analytics-browser";
import { events } from "./events";

export type TrackingOptions = {
  usernameOrEmail?: string;
  [key: string]: unknown;
};

export function normalizeTrackingOptions(
  options?: TrackingOptions
): { [key: string]: unknown } | null {
  if (!options) {
    return null;
  }

  const { usernameOrEmail, ...rest } = options;

  if (usernameOrEmail) {
    if (usernameOrEmail.includes("@")) {
      rest.email = usernameOrEmail;
    } else {
      rest.username = usernameOrEmail;
    }
  }

  return rest;
}

export function initialize(): void {
  Amplitude.init("752b22f89fd7decee64a2d75f2449e2c", {
    flushIntervalMillis: 10000,
    defaultTracking: true,
  });
}

export function identify(id: string | null, options?: TrackingOptions): void {
  initialize();
  const properties = normalizeTrackingOptions(options);

  if (!id) {
    Amplitude.reset();
    return;
  }

  Amplitude.setUserId(id);

  if (properties) {
    // Remove any properties that are undefined
    const safeProperties = Object.keys(properties).reduce(
      (acc, key) => ({
        ...acc,
        ...(properties[key] !== undefined && { [key]: properties[key] }),
      }),
      {} as { [key: string]: any }
    );

    const identifyEvent = new Amplitude.Identify();
    for (const key in safeProperties) {
      identifyEvent.set(key, safeProperties[key]);
    }
    Amplitude.identify(identifyEvent);
  }
}

export function setUserProperty(key: string, value: string | number | boolean) {
  const identifyEvent = new Amplitude.Identify();
  identifyEvent.set(key, value);
  Amplitude.identify(identifyEvent);
}

export function track(
  event: keyof typeof events,
  options?: TrackingOptions
): void {
  initialize();
  const properties = normalizeTrackingOptions(options);

  if (properties) {
    Amplitude.track(event, properties);
  } else {
    Amplitude.track(event);
  }

  console.debug(event, properties);
}

export function flush(): void {
  Amplitude.flush();
}

export default {
  events,
  initialize,
  identify,
  track,
  flush,
};
